<template>
  <!-- Bundle ve ticari ürün için göstermiyoruz -->
  <div v-if="product?.variantGroups.length >= 1 && product?.productType === 'combine'">
    <div class="font-bold text-2xl md:text-3xl text-primary mb-2.5">{{ $t('listing.product-variants') }}</div>
    <div class="bg-gray-100 px-4 py-2.5 rounded-lg border border-gray-200" >
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-5 mt-2">
        <div
            v-for="color in product?.variantGroups"
            :key="color?.colorCode"
            class="relative overflow-hidden w-full"
        >
          <div class="absolute left-0 top-0 p-2 text-sm font-medium">{{$t('app.color')}} : {{ color.colorName }} </div>
          <NuxtImg
              :src="getImage(color?.thumbnail)"
              width="240"
              height="160"
              :alt="color?.colorCode"
              v-if="color?.url && color?.url !== 'null'"
              :class="['border-2', color?.colorCode === selectedVariant?.colorCode ? 'border-primary' : 'border-transparent']"
              class="w-full rounded-md object-contain cursor-pointer"
              @click="setSelectedVariant(color);"
          />

          <SectionMiniAddToCartSectionForColors class="mt-2.5 rounded-md" :selected-variant="color" v-if="!hidePricesForGuest" />

          <!-- Fiyatları görebilmek için bayi girişi yapınız -->
          <div class="text-base text-gray-600 pt-2.5" v-else>
            <i18n-t keypath="listing.please-login-for-the-prices" tag="div">
              <a href="/login" class="text-login">{{$t('listing.dealer-login')}}</a>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const {
  product,
  selectedVariant,
  setSelectedVariant
} = await useProduct()

const {hidePricesForGuest} = useHelper()

</script>